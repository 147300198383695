export const fr = {
  tooltip: "Complétez d'abord le chapitre I",
  login: {
    titre: ["Connexion", "Inscription", "Mot de passe oublié"],
    label: [
      "Nom",
      "Prénoms",
      "Nom d'utilisateur",
      "Mot de passe",
      "Réécrire mot de passe",
      "Email",
      "Téléphone",
      "Langage",
      "Pays",
      "Région",
      "Commune",
      "Quel est votre rôle dans TERAKA ?",
      "Êtes-vous dans un petit groupe ?",
      "Quel est le numéro du petit groupe ?",
      "Accepter les termes et conditions d'utilisation",
      "Methode de récupération",
      "Envoyer un email",
      "Répondre au question",
      "Terminés",
      "Nouveau mot de passe",
    ],
  },
  button: [
    "Programme",
    "Rejoindre",
    "Mon compte",
    "Lexique",
    "F A Q",
    "Déconnexion",
    "Connexion",
    "S'inscrire",
    "Mot de passe oublié",
    "Rechercher",
    "Valider",
    "Voir",
    "Suite",
    "Terminer",
    "Précedent",
  ],
  label: "Formation hors-ligne",
  alert: [
    "Bienvenue à bord",
    "Félicitation!",
    "Vous avez fini cette leçon",
    "Les champs sont obligatoires!",
    "Minimum 8 caractères pour le mot de passe",
    "Les mots de passe ne sont pas identiques",
    "Veuillez accepter les conditions d'utilisation",
  ],
  question: [
    "Comment s'appelle ton chien?",
    "Où est ta ville natale?",
    "Tu veux vivre où?",
    "Quel est ton film preféré?",
  ],
  cours: [
    {
      titre: "Comment utiliser le Centre de formation",
      descri:
        "Cette formation montre aux gens comment utiliser le centre d'apprentissage TERAKA, y compris comment passer d'une formation à la suivante. C'est un bon point de départ dans le centre d'apprentissage TERAKA. Une fois que vous avez terminé cette formation, vous pouvez retourner au catalogue de cours et choisir un autre cours pour commencer.",
      liste: [
        {
          titre:
            "Apprendre ici: Comment utiliser le centre de formation TERAKA",
        },
        {
          titre: "Apprendre ici: Comment télécharger une formation PDF TERAKA",
        },
      ],
    },
    {
      titre: "Introduction pour les Agriculteurs candidat TERAKA",
      descri:
        "Cette formation contient des cours sur le fonctionnement de TERAKA, c'est un formation nécessaire avant d'accéder au prochain chapitre. Pour bien débuter pour devenir un membre TERAKA, cette formation est un bon point pour débuter. Il y a sept (7) sous-chapitres totalement indépendants l'un à l'autre, il est mieux de suivre la formation dans l'ordre des sous-chapitres.",
      liste: [
        {
          titre: "Valeurs TERAKA de base",
        },
        {
          titre: "La plantation d’arbres et TERAKA",
        },
        {
          titre: "La plantation d’arbres et les Crédits carbone",
        },
        {
          titre: "La structure du Petit Groupe TERAKA",
        },
        {
          titre: "Comment devenir un Petit Groupe TERAKA",
        },
        {
          titre: "Comprendre les paiements et le contrat GES TERAKA",
        },
        {
          titre: "Faire grandir TERAKA",
        },
      ],
    },
  ],
  c101: [
    "Types de trafic parascolaire",
    "1. Barre supérieure :",
    "Cela vous permet de naviguer dans le site ou de parcourir d'autres sites TERAKA. Vous pouvez cliquer sur l'un d'entre eux pour passer à un autre site TERAKA.",
    "Lien Web",
    "2. Chemin d'accès :",
    "C'est ainsi que vous pouvez savoir où vous êtes, tous les chemins que vous avez empruntés pour arriver là où vous êtes maintenant. Vous pouvez tracer le chemin si vous voulez retourner à un endroit.",
    "Chemin à parcourir et où vous êtes ",
    "3. Compte :",
    "C'est ici que vous pouvez visualiser les cours que vous souhaitez, ou encore quitter le site \"Déconnexion\". Vous pouvez cliquer sur l'un d'entre eux.",
    "Compte TERAKA",
    "4. Alerte :",
    "C'est ainsi que la page communique avec vous. Vous pouvez voir les petits mots, le sens dépend de la couleur.",
    "Notification",
    "Types de trafic dans la leçon",
    "1. Affichage :",
    "C'est ici que vous pouvez modifier l'affichage de la liste des leçons. Vous pouvez cliquer sur l'un d'entre eux si vous souhaitez modifier l'affichage de la liste des leçons.",
    "Modifier le format de la liste",
    "2. Affichage de la calandre :",
    "Voici à quoi cela ressemble si votre choix est En grille. Il apparaît également en premier si vous n'avez pas choisi de changer. Vous pouvez cliquer sur une leçon pour continuer à l'apprendre.",
    "Liste Grille",
    "3. Affichage en ligne :",
    "Voici à quoi cela ressemble si votre sélection est en ligne. Vous pouvez cliquer sur une leçon pour continuer à l'apprendre.",
    "Liste en ligne",
    "4.Navigation :",
    "Ce sont les boutons qui vous permettront de lire la leçon. Lorsque vous cliquez sur continuer, votre taux d'achèvement de la leçon augmentera.",
    "Boutons en cours",
    "5. Résiliation :",
    "Ce sont les boutons de fin qui vous permettent de terminer la leçon et d'évaluer la leçon telle que vous la voyez. Lorsque vous cliquez sur Terminer, vous terminez la leçon.",
    "Bouton de fin de leçon",
    "6. Chapitre :",
    "Cela montre les informations d'un chapitre. Ici, vous pouvez voir les leçons qui couvrent le chapitre et aussi les leçons que vous avez terminées. La couleur de la leçon est verte si elle est terminée à 100 %, bleue s'il reste encore des leçons à terminer. Vous pouvez cliquer sur une leçon si vous voulez l'apprendre.",
    "Description d'un chapitre",
  ],
  c102: [
    "Comment télécharger les fichiers du cours ?",
    "Dans chaque cours, il y a une section au tout début qui liste les fichiers téléchargeables du cours.",
    "Cliquer sur eux pour lancer un téléchargement",
    "Section Liste des fichiers",
  ],
  c201: [
    "QUI EST TERAKA ?",
    "TERAKA est composé d'agriculteurs qui se portent volontaires pour rejoindre la communauté TERAKA. TERAKA est constitué de petits groupes. Nous travaillons ensemble pour développer les meilleures pratiques et améliorer nos fermes et notre environnement.",
    "VALEURS TERAKA",
    "Nous sommes honnêtes.",
    "Nous sommes précis.",
    "Nous sommes transparents.",
    "Nous sommes mutuellement responsables.",
    "Nous sommes des serviteurs les uns des autres.",
    "Nous sommes volontaires.",
    "Nous créons des grands résultats avec des faibles budgets.",
    "COMMENT SOMMES-NOUS DIFFERENTS ?",
    "Nous sommes des volontaires. Les agriculteurs rejoignent TERAKA pour la communauté, l'éducation, l'augmentation des connaissances agricoles et pour participer à l'activité. Nous développons et utilisons les meilleures pratiques Les agriculteurs de TERAKA développent et partagent des informations sur les activités de conservation agricole, foresterie, nutrition, et les activités pour le développement durable. Nous partageons ces meilleures pratiques avec tous les Petits Groupes.",
    "QU'EST-CE QUE NOUS FAISONS ?",
    "FAIRES POUSSER DES ARBRES",
    "À mesure que les arbres poussent, la photosynthèse réduit le dioxyde de carbone dans l'air en le stockant dans le bois, les racines et le sol. Notre partenaire, iTERAKA , prend toutes les mesures nécessaires pour transformer ce carbone en crédits carbone puis les vend sur le marché du carbone. TERAKA Les agriculteurs reçoivent un prépaiement chaque année et recevront également 70% des bénéfices de la vente des crédits carbone.",
    "DEVELOPPEMENT DES LEADERS",
    "Nous développons également des leaders. En utilisant “ Rotating Leadership ”, tous les agriculteurs de TERAKA apprennent à diriger leur petit groupe.",
    "PARTAGES DES BONNES PRATIQUES",
    "Les agriculteurs de TERAKA partagent ces meilleures pratiques avec tous les petits groupes. Nous avons des gazettes mensuelles, des réunions mensuelles locales des clusters et des séminaires régionaux où nous partageons les meilleures pratiques que nous avons développées.",
    "QU'EST-CE QUE NOUS CREONS ?",
    "Nous sommes dans le business du carbone",
    "Les agriculteurs de TERAKA sont dans le secteur du carbone pour soutenir leurs familles et améliorer leurs fermes, leur communauté et la planète.",
    "Nous créons des credits carbones",
    "Nous nous finançons ainsi que nos efforts en mesurant très précisément la quantité de dioxyde de carbone que nos pratiques agricoles et nos arbres ont éliminée de l'atmosphère.",
    "Nous générons des nouvelles revenues pour nos familles.",
    "Nous travaillons avec iTERAKA pour mesurer nos résultats de manière précise et transparente afin que nous puissions entrer sur le marché mondial du carbone.",
    "Nous utilisons des faibles budgets pour créer des grands résultats.",
    "TERAKA et iTERAKA ont travaillé dur pendant plus de 20 ans pour minimiser le coût du programme afin que plus d'argent carbone soit versé aux agriculteurs de TERAKA.",
    "POURQUOI LES VALEURS DE TERAKA SONT IMPORTANTS ?",
    "Nous créons la confiance",
    "TERAKA ne fonctionne que si nous sommes dignes de confiance: nous vendons des crédits carbone que nous ne pouvons pas voir, toucher ou goûter.",
    "Ils aidents pour avoir des benefices.",
    "Les valeurs TERAKA aident les agriculteurs à obtenir de grands résultats. En opérant avec des budgets bas qui génèrent de gros résultats, nous créons plus d'argent carbone pour les agriculteurs de TERAKA.",
    "Par les agriculteurs, pour les agricultuers.",
    "TERAKA est fait par les agriculteurs, pour les agriculteurs. En suivant les valeurs TERAKA, nous travaillons les uns pour les autres et pour nous-mêmes. Ces valeurs soutiennent la communauté et la responsabilité.",
    "Serviteurs les uns aux autres",
    "Les valeurs de TERAKA nous aident à se server les uns les autres.",
  ],
  c202: [
    "LES AVANTAGES DE PLANTER DES ARBRES",
    "LA PLANTATION D'ARBRES RESTAURE LES ZONES DÉBOISÉES",
    "La plantation des arbres aide à arrêter l'érosion du sol et les glissements de terrain Les racines des arbres aident à stabiliser le sol et à réduire l'érosion causée par le vent et les inondations. La plantation des arbres sur les flancs réduit le risque de glissement de terrain.",
    "LES ARBRES FOURNISSENT DES FRUITS ET DES NOIX",
    "De nombreuses espèces d'arbres produisent des fruits, tels que l'avocat et les mangues, ou des noix, comme la macadamia. Ceux-ci peuvent être vendus ou utilisés pour nourrir la famille.",
    "CERTAINS ARBRES FOURNISSENT DES MÉDICAMENTS",
    "Certaines espèces d'arbres, comme la cassie ancienne ou queue-de-rat, contiennent des propriétés médicinales utiles.",
    "QUE SIGNIFIE PLANTER DES ARBRES AVEC TERAKA ?",
    "L’arbre doit atteindre au moins 2 mètres de hauteur",
    "1 an - 60 ans",
    "2 mètres",
    "NB:",
    "Les agriculteurs de TERAKA peuvent planter des arbres à moins de 2 mètres pour d'autres raisons, comme le bois de chauffage. Ces arbres ne rapporteraient pas de monnaie carbone. Les arbres doivent être plantés sur votre propre terrain ou avec l'autorisation du propriétaire. Bien que cela ne soit pas obligatoire, les participants à TERAKA sont encouragés à construire un lit de semences surélevé pour aider à cultiver les jeunes plants. Ces plants d'arbres peuvent ensuite être plantés et utilisés pour générer des revenus supplémentaires.",
  ],
  c203: [
    "C’EST QUOI CARBONE ?",
    "Le carbone est partout autour de nous, mais nous ne pouvons ni le voir, ni le toucher, ni le goûter alors qu'est-ce que c'est ?",
    "Les arbres qui nous font de l'ombre sont faits de carbone.",
    "Les animaux que nous élevons sont faits de carbone.",
    "Les légumes que nous cultivons sont faits de carbone Même vous et moi sommes faits de carbone.",
    "Les combustibles que nous utilisons tous les jours sont également composés de carbone : les combustibles comme l'essence et le bois de chauffage pour la cuisine.",
    "Lorsque nous brûlons des combustibles à base de carbone, nous produisons de la fumée et des gaz d'échappement qui salissent l'air que nous respirons et noircissent nos toits en tôle.",
    "QU'EST CE QUE LE CYCLE DU CARBONE ?",
    "1ère phase :",
    "Le carbone est présent partout sur Terre. Il régule la température de la Terre, est à la base de toutes les inondations et constitue une source majeure de carburant. Le carbone est à la base de toute vie sur Terre. Le carbone se trouve dans notre atmosphère sous la forme de dioxyde de carbone ou CO2. On ne peut ni le voir, ni le goûter, ni le sentir, mais il est partout autour de nous.",
    "2ème phase :",
    "Les sécheresses plus longues et les températures plus élevées sont toutes deux des conséquences du changement climatique. Mais il y a une chose simple que nous pouvons tous faire pour lutter contre les causes du changement climatique : Planter des arbres : les arbres absorbent le CO2 de l'atmosphère pendant leur croissance",
    "TERAKA ET LE BUSINESS DU CREDIT CARBONE ?",
    "L'activité de crédit carbone est la façon dont la iTERAKA reçoit des fonds pour gérer le programme TERAKA",
    "Les agriculteurs de TERAKA rejoignent un petit groupe dans le but d'aider l'environnement",
    "Le petit groupe TERAKA signe un contrat avec iTERAKA , la société qui gère le programme TERAKA",
    "Ce contract est appelé Contrat de Gas à Effet de Serre ou Contrat GES",
    "Les Petits Groupes de TERAKA maintiennent les arbres en vie.",
    "Un agent de Cluster TERAKA viendra compter les arbres et mesurer leur croissance.",
    "Cela permet à la iTERAKA de calculer la quantité de carbone que les arbres ont absorbée dans l'air.",
    "La iTERAKA engage des vérificateurs tiers pour s'assurer de l'exactitude de cette mesure.",
    "La iTERAKA crée un crédit carbone à partir de la quantité de carbone que les arbres TERAKA ont éliminé de l'air.",
    "Les entreprises achètent ce crédit carbone à la iTERAKA",
    "C'est ainsi que les Petits Groupes de TERAKA bénéficient de l'activité carbone.",
    "NOTES IMPORTANTES :",
    "Prépaiements du carbone",
    "Au début, les arbres TERAKA seront trop petits pour créer un crédit carbone. Jusqu'à ce que l'arbre TERAKA devienne suffisamment grand, les petits groupes TERAKA recevront un paiement anticipé de 0,02 € par arbre et par an pour maintenir la croissance de l'arbre.",
    "Partage du profit de carbone",
    "Lorsque l'arbre est suffisamment grand et que le petit groupe remplit toutes les conditions d'admissibilité, le petit groupe TERAKA reçoit une participation aux bénéfices. La participation aux bénéfices est créée lorsque la iTERAKA vend des crédits de carbone à une autre personne ou entreprise, et que la iTERAKA partage 70 % des bénéfices de la vente avec les petits groupes TERAKA.",
    "Création d’un credit carbone",
    "Pour créer un crédit carbone, un serviteur de TERAKA comptera les arbres de chaque bosquet, en mesurant la circonférence du tronc, en notant l'espèce de l'arbre et la proximité des arbres entre eux. Ces informations sont ensuite ajoutées à la base de données TERAKA. La iTERAKA utilise ces informations pour créer un crédit carbone. Ce crédit carbone est ensuite vendu à une autre personne ou entreprise désireuse d'aider l'environnement.",
    "AUTRES AVANTAGES DE TERAKA",
    "Fourneaux améliorés",
    "Agriculture de conservation",
    "Formation sur le compostage du fumier",
    "Cela permet aux agriculteurs d'obtenir de meilleurs rendements, d'aider l'environnement et d'éviter d'acheter des engrais.",
    "Santé",
    "La santé d'un agriculteur est sa plus grande ressource. Les agriculteurs de TERAKA reçoivent des formations pour rester en bonne santé ainsi que des formations sur les problèmes de santé courants.",
    "Développement du leaderdship",
    "Chaque agriculteur de TERAKA a la possibilité d'être un leader de TERAKA et de recevoir une formation en leadership Les agriculteurs de TERAKA pratiquent le leadership par rotation, ce qui donne à chacun la possibilité d'être un leader.",
  ],
  c204: [
    "COMMENT FORMER UN PETIT GROUPE SOLIDE ?",
    "Un petit groupe",
    "Les petits groupes de TERAKA sont composés de 6 à 12 membres issus d'au moins 3 familles différentes qui vivent toutes à proximité les unes des autres.",
    "Vous devez appartenir à un petit groupe pour rejoindre TERAKA. Réfléchissez bien avant de former un petit groupe. Les petits groupes sont constitués d'amis et de voisins, et ils permettent aux agriculteurs de TERAKA de se développer en tant que leaders. Les bons petits groupes TERAKA sont constitués de personnes qui travaillent dur et qui incarnent les valeurs de TERAKA. Les hommes et les femmes d'un même petit groupe sont souvent ceux qui accomplissent le plus de choses.",
    "LES PROMESSES DU PETITS GROUPES DE TERAKA",
    "Lorsqu'un petit groupe TERAKA adhère au programme, ses membres acceptent de :",
    "S'engager à planter au moins 1 000 arbres par an pendant 5 ans",
    "Se réunir au moins une fois par semaine",
    "Signer ensemble le contrat sur les gaz à effet de serre",
    "Incarner les valeurs de TERAKA",
    "Utiliser le leadership par rotation et le Kujengana",
    "LES RÔLES DU LEADERSHIP DES PETITS GROUPES",
    "Le responsable est au service du petit groupe, il dirige les réunions et veille à ce que chaque personne puisse participer.",
    "Le responsable",
    "Aide à diriger le petit groupe et fait office de chronométreur. Le co-responsable devient le responsable lors de la réunion suivante.",
    "Le co-leader",
    "Rappelle au petit groupe ses responsabilités et ses engagements passés et présents. La personne chargée de la responsabilité devient le co-responsable lors de la réunion suivante.",
    "La personne chargée de la responsabilité",
    "LEADERSHIP TOURNANT",
    "La rotation du leadership est une pratique exemplaire de TERAKA pour les petits groupes. Le leadership est renouvelé à chaque réunion (hebdomadaire), le co-responsable devenant le leader, la personne chargée de la responsabilité devenant le co-responsable et une nouvelle personne chargée de la responsabilité étant élue.",
    "Le leadership alterne entre hommes et femmes à chaque niveau de TERAKA. Cela signifie que si la personne responsable actuelle est un homme, la prochaine devrait être une femme. Ainsi, la direction alterne toujours entre les hommes et les femmes.",
    "LES AVANTAGES DU LEADERSHIP TOURNANT",
    "Chaque personne a la possibilité de servir en tant que leader.",
    "Les hommes et les femmes ont les mêmes chances.",
    "Nous apprenons les uns des autres ce qui fait un bon dirigeant.",
    "La rotation du leadership encourage les nouveaux venus et les personnes timides à prendre la tête d'un groupe.",
    "KUJENGANA",
    "C'est quoi le Kujengana",
    "Kujengana est le mot swahili qui signifie construire, et c'est quelque chose que l'on fait à la fin de chaque réunion de TERAKA.",
    "Comment pratiquer le Kujengana ?",
    "Avant la prière de clôture, chaque membre du petit groupe dit une chose spécifique et positive que le responsable a faite au cours de la réunion. Ces commentaires doivent être spécifiques et se référer à quelque chose que le responsable a fait ou dit. Pensez à quelque chose qu'une mouche sur le mur verrait ou entendrait. Il ne doit pas s'agir d'un commentaire général du type le responsable a fait du bon travail. Il s'agit plutôt d'un commentaire spécifique comme le responsable a respecté le temps imparti, a souri et a encouragé tous les membres du petit groupe à s'exprimer.",
    "Pourquoi pratiquer le Kujengana ?",
    "Chaque membre de TERAKA possède des talents et des dons particuliers et a quelque chose de précieux à apporter. Le Kujengana est la pratique qui consiste à exprimer cette valeur à haute voix. Tout comme la rotation du leadership, le Kujengana est un élément important de la croissance des leaders au sein de TERAKA.",
    "Les bénéfices du Kujengana",
    "Il donne confiance aux dirigeants lorsqu'ils ont fait quelque chose de bien.",
    "Il aide les futurs dirigeants à s'inspirer des bonnes idées des dirigeants actuels.",
    "Il encourage les gens à devenir des leaders",
    "CLUSTER DE TERAKA",
    "Chaque petit groupe TERAKA fait partie d'une grappe de petits groupes. Une grappe est un groupe de 30 à 50 petits groupes TERAKA qui se trouvent à proximité les uns des autres.",
    "Comment former un Cluster ?",
    "Chaque Cluster doit compter entre 30 et 50 petits groupes qui ont:",
    "planté au moins 200 000 arbres au total (chaque petit groupe doit planter 1 000 arbres par an)",
    "un lit de pépinière pour élever les plantules",
    "environ 80 à 100 hectares de terrain pour la plantation d'arbres.",
    "RÉUNION DES CLUSTERS",
    "Chaque mois, chaque petit groupe TERAKA du groupe envoie deux représentants (un homme et une femme) à une réunion de groupe. Un agent de Cluster de TERAKA organise ces réunions dans un lieu facilement accessible à tous les petits groupes du groupe. Lors de la réunion de grappe, les agriculteurs de TERAKA :",
    "reçoivent la gazette mensuelle de TERAKA, qu'ils rapportent à leur petit groupe TERAKA",
    "reçoivent un bon de paiement pour l'activité de plantation d'arbres de leur petit groupe de TERAKA",
    "reçoivent une formation supplémentaire",
    "partagent les meilleures pratiques et les nouvelles avec les membres des autres petits groupes de TERAKA de la région",
  ],
  c205: [
    "QUELQUES DÉFINITIONS",
    "Un petit groupe TERAKA",
    "Comme cela a été expliqué, le Petit Groupe TERAKA est une association de 6 à 12 agriculteurs qui se réunissent volontairement et librement pour planter des arbres ensemble, pour l'amélioration de la terre et de la communauté. Il y a 3 familles différentes dans le Petit Groupe.",
    "Une Cluster",
    "Comme expliqué précédemment, le cluster de TERAKA se compose de 30 villages de 50 petites communautés qui ne sont pas loin les unes des autres, ont des terres pour planter 200 000 arbres (environ 80 hectares) et ont une pépinière pour élever des enfants.",
    "PROCESSUS DE DEMANDE DE FORMATION ET D'ADHÉSION",
    "Etape 1 : Visitez le",
    "Les agriculteurs qui souhaitent devenir membre de TERAKA doivent visiter leur site Web :",
    " et utilisez le centre de formation TERAKA.",
    "Étape 2 : Si possible, assistez aux réunions de cluster",
    "Les agriculteurs doivent se renseigner pour savoir qu'il y a une réunion avec la cluster de TEREKA.",
    "Étape 3 : Créer un petit groupe solide dans TERAKA",
    "Revoir attentivement la formation sur la structure du Petit Groupe dans 4e sous chapitre du 2e chapitre du centre pédagogique.",
    "Etape 4 : Devenez membre de TEREKA",
    "Une fois que le petit groupe a terminé la formation, un serviteur de groupe ou un champion TERAKA remplit soit:",
    "la demande d'inscription du Petit Groupe dans le centre d'apprentissage",
    "un dossier de candidature TERAKA",
    "Etape 5 : Organiser des réunions en Petits Groupes",
    "Les petits groupes doivent se réunir chaque semaine et consigner les détails de la réunion et, si possible, assister aux réunions mensuelles du groupe.",
    "Etape 6 : Décider où planter les arbres",
    "Un petit groupe doit décider de l'endroit où il plantera ses arbres TERAKA.",
    "Etape 7 : Si vous remplissez le formulaire de demande, soumettez-le aux agent de Cluster.",
    "Si un petit groupe estime avoir avoir rempli toutes les conditions d'admission au programme TERAKA, il peut demander à un agent Cluster de groupe de devenir un Petit Groupe de TERAKA.",
    "Etape 8 : Si vous remplissez le formulaire de demande sur le centre d'apprentissage",
    "Le Coach de TERAKA examinera les informations afin de remplir le formulaire de demande pour les Petits Groupes sur le centre de formation.",
    "BASELINE",
    "La baseline est l'état du bosquet et du petit groupe lorsque vous rejoignez le programme TERAKA pour la première fois.",
    "Qui doit être présent lors de la collecte des informations de base ?",
    "Lorsque le représentant de TERAKA rend visite à votre petit groupe, tous les membres du petit groupe doivent être présents et apporter tous les documents attestant de l'accès à la terre.",
    "Quelles sont les informations collectées pour la baseline ?",
    "• Arbres de référence - arbres présents dans le bosquet avant que le petit groupe n'entende parler de TERAKA (il ne peut s'agir d'arbres TERAKA)",
    "• Référence du bosquet",
    "• Souches d'arbres dans le bosquet, utilisation du bosquet avant la plantation d'arbres TERAKA et autres faits concernant le bosquet",
    "• Photographies : a) du petit groupe ; b) de chaque bosquet ; c) des parcelles d'agriculture de conservation ; d) des poêles ; et e) des pépinières.",
  ],
  c206: [
    "RESPONSABILITÉS",
    "Les responsabilités des petits groupes",
    "Le Petit Groupe s'engage à planter au moins 1 000 arbres par an pendant 5 ans",
    "Le Petit Groupe s'engage à garder les arbres en vie pendant 60 ans et à replanter ceux qui meurent",
    "Le Petit Groupe s'engage à participer aux formations dispensées par TERAKA et à contribuer au développement des bonnes pratiques",
    "Le groupe minoritaire accepte de permettre aux classificateurs et vérificateurs de TERAKA de venir à la plantation pour mesurer la croissance des arbres",
    "Les responsabilités de iTERAKA",
    "iTERAKA s'engage à fournir des informations dans les formations, les médias et sur Internet",
    "iTERAKA convient qu'il est précis et transparent dans la mesure",
    "iTERAKA s'engage à payer les 70 % du Petit Groupe qui est le bénéfice de la vente du carbone et de faire le paiement anticipé de l'arbre vivant qui est au moins 6 mois par an qui vaut 0,03 euro à 0,03 $ (euro )",
    "iTERAKA s'engage à faire ses meilleurs efforts dans la vente de crédits carbone au profit de petits groupes",
    "COMPRÉHENSION DE PRÉPAIEMENT",
    "Arbre de deux (2) mois",
    "Pour pouvoir recevoir l'acompte",
    "Arbre de quatre (4) mois",
    "Pour pouvoir recevoir l'acompte...",
    "Arbre de dix-huit (18) mois",
    "Pour recevoir l'acompte, les arbres doivent avoir été comptés comme des arbres dans les 18 mois L'acompte est de 0,03 euro par an pour chaque arbre vivant",
    "Arbre adulte",
    "Le paiement anticipé est également le paiement minimum qu'iTERAKA fournit annuellement pour chaque arbre vivant.",
    "REPARTITION DES BENEFICES",
    "Par exemple, si la vente d'un crédit carbone crée 1 000 unités monétaires (unités) de revenus carbone",
    "Et nous pensons que le prix est de 200 unités. Le bénéfice restant est de 800 Unités",
    "iTERAKA s'engage à partager 70 %, soit 560 Unités, avec le petit groupe",
    "Et 30 %, soit 240 Unités, seront inclus dans le Programme TERAKA",
    "Ainsi, dans cet exemple, le crédit carbone vendu pour 1 000 Unités est utilisé pour 560 Unités qui reviennent à des petits groupes",
  ],
  c207: [
    "DÉVELOPPEMENT DE TERAKA : COMMENT TERAKA SE DÉVELOPPE-T-IL ?",
    "Étape 1 : Premier contact",
    "Les agriculteurs entendent parler du programme TERAKA lors d'un séminaire organisé par TERAKA, d'une formation interne, d'une visite avec un membre de TERAKA, d'émissions de radio, de journaux ou de la gazette mensuelle, ou de bien d'autres façons.",
    "Étape 2 : Sensibilisation et recrutement",
    "Si un agriculteur souhaite en savoir plus sur TERAKA, il doit se rendre sur le site join.TERAKA.org et demander s'il y a des réunions de groupe TERAKA dans sa région. Discuter avec des agriculteurs TERAKA et assister aux réunions de groupe (si possible).",
    "Étape 3: Formation et application",
    "Revoir le 5e sous chapitre du chapitre 2: Comment devenir membre de Petit Groupe TERAKA pour aider les agriculteurs à former des petits groupes solides qui comprennent le programme TERAKA.",
    "Étape 4 : Formation d'un Cluster",
    "Un Cluster est une combinaison de 30 à 50 Petits Groupes qui se trouvent à une distance de marche. S'il y a moins de 30 petits groupes dans votre région, vous pouvez quand même créer un groupe. Continuez à ajouter des petits groupes jusqu'à ce que vous atteigniez 30 !",
    "Étape 5: AGIR et FAIRE",
    "A ce stade, les agriculteurs en savent assez et doivent être encouragés à AGIR et à FAIRE ! Poursuivre la formation des agriculteurs TERAKA en utilisant le matériel du Centre d'apprentissage et en partageant les meilleures pratiques en matière de petits groupes et de clusters Revoir les formations du Centre d'apprentissage pour savoir comment enregistrer le petit groupe une fois qu'il a compris le programme.",
    "MOYENS DE RECRUTER POUR L'EXPANSION DE TERAKA",
    "Mobilisation par le biais de réunions communautaires.",
    "Créez une pépinière surélevée afin de disposer plusieurs plantules.",
    "Demandez aux autres membres de TERAKA près de chez vous pour vous aider à recruter leurs voisins.",
    "Mobiliser en travaillant avec les dirigeants locaux.",
    "Expliquer aux membres de la communauté l'importance de la plantation d'arbres et les avantages de participer à TERAKA.",
    "Partager la gazette mensuelle de TERAKA avec des personnes en dehors de TERAKA.",
    "Montrer aux gens les avantages tels que les foyers améliorés, les paiements pour les arbres, les arbres fruitiers ou l'agriculture de conservation.",
    "Mobiliser par l'intermédiaire des églises.",
    "Mobilisation par le biais de la radio locale.",
  ],
};
